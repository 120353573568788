@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');


.App {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  font-style: normal;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CenterPanel {
  width: 100vw;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 12px;
}

.CenterPanel img {
  width: 200px;
  margin-left: -65px;
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 40px;
}

.CenterPanel p {
  text-align: center;
  width: 300px;
}

a {
  color: #14532d;
}

canvas {
  position: absolute;
  pointer-events: none; 
}
